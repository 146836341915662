<template>
   <div class="prop-obj">
 <b-row class="pr-2 pl-2 pt-2">
  <b-col cols="12" class="mb-1 text-center">
    <p>
      {{ $t('you_have') }}
      <span style="color: #9600ff !important">{{ nbrTaches }}</span>
      {{ $t('sub_objectives_total_hours') }}
      <span style="color: #9600ff !important">{{ data.dedicatedTime }}</span>
      {{ $t('and') }}
      <span style="color: #9600ff !important">{{ hoursPer }}</span>
      {{ $t('hours_per') }}
      <span style="color: #9600ff !important">{{ data.timeline }}</span>
    </p>
  </b-col>
  <b-col cols="12" v-if="data.timeline==='Day'">
    <b-form-group :label="$t('date_label')" label-for="date">
      <b-form-input
        id="date"
        :placeholder="$t('date_placeholder')"
        v-model="dateDays"
        readonly
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" v-if="data.timeline!=='Day'">
    <b-form-group :label="$t('start_date_label')" label-for="startDate">
      <b-form-input
        id="startDate"
        :placeholder="$t('start_date_placeholder')"
        v-model="date.startDate"
        readonly
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" v-if="data.timeline!=='Day'">
    <b-form-group :label="$t('end_date_label')" label-for="endDate">
      <b-form-input
        id="endDate"
        :placeholder="$t('end_date_placeholder')"
        v-model="date.endDate"
        readonly
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" v-if="data.timeline==='Month'">
    <b-form-group :label="$t('week_label')" label-for="week">
      <v-select
        id="week"
        :placeholder="$t('week_placeholder')"
        v-model="week"
        :options="weeksOption"
        @input="changeDays"
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" :lg="data.timeline === 'Month' ? '6' : '12'" v-if="showDay()">
    <b-form-group :label="$t('day_label')" label-for="day">
      <v-select
        id="day"
        :placeholder="$t('day_placeholder')"
        v-model="day"
        :options="daysOption"
        @input="changeAvail()"
      />
    </b-form-group>
  </b-col>
</b-row>
<b-row class="pr-2 pl-2 pb-2" v-if="show">
  <b-col cols="12" class="mt-2" v-if="showAvailTime">
    <b-form-group :label="$t('available_times_label')">
      <p v-if="times.length === 0" style="font-weight: bold;">{{ $t('no_available_times') }}</p>
      <b-form-checkbox-group v-else v-model="selectedTimes" :options="times" name="flavour-2a" stacked>
      </b-form-checkbox-group>
    </b-form-group>
  </b-col>
</b-row>
<b-row class="pr-2 pl-2 pb-2" v-if="show === false">
  <b-col cols="12" class="mt-2">
    <b-row v-for="(time, i) in availTimes" :key="i" class="d-flex align-items-center">
      <b-col cols="12" lg="5">
        <b-form-group :label="$t('from_label')">
          <datetime type="time" v-model="time.start"></datetime>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="5">
        <b-form-group :label="$t('to_label')">
          <datetime type="time" v-model="time.end"></datetime>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="2" class="pt-1">
        <feather-icon class="add-time-icon mr-1" icon="PlusCircleIcon" @click="Addtimeline"></feather-icon>
        <feather-icon v-if="i > 0" class="remove-time-icon" icon="MinusCircleIcon" @click="Minustimeline(i)"></feather-icon>
      </b-col>
    </b-row>
  </b-col>
</b-row>

      <div class="d-flex justify-content-between">
         <div>
            <b-button
            class="mr-2"
               variant="outline-secondary"
               @click="returnStep1"
               >
               Return 
            </b-button>
            <b-button
            class="mr-2"
               variant="outline-secondary"
               @click="addManAuto"
               >
               {{ show ? "Add manuelly" : "Add automatically" }}
            </b-button>
         </div>
         <div class="d-flex justify-content-end mb-2">
            <!--<b-button
               v-if="taches.length!==0"
               class="mr-2"
               variant="outline-secondary"
               @click="back()"
               >
               Back
            </b-button>-->
            <b-button
               variant="outline-secondary"
               @click="add"
               >
               {{ buttonText }}
            </b-button>
         </div>
      </div>
   </div>
</template>

<script>
import {
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
   BButton,
   BFormCheckboxGroup,
   BFormDatepicker
} from "bootstrap-vue";
import moment from 'moment';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Datetime } from "vue-datetime";
import vSelect from "vue-select";
import axios from "axios";

export default {
   components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BFormCheckboxGroup,
      BFormDatepicker,
      Datetime,
      vSelect
   },
   props: ['data'],
   data() {
      return {
         day : "",
         week : "",
         show : true,
         taches : [],
         days : [],
         dates : [],
         times : [],
         fullTimes : [],
         dateDays : "",
         date : {},
         currentIndex: 0,
         hoursPer : null,
         nbrTaches : null,
         selectedTimes : [],
         maxDate : null,
         minDate : null,
         availTimes : [
            {
               start : "00:00",
               end : "00:00"
            }
         ],
         timings: {
            Morning: { start: '06', end: '12' },
            Afternoon: { start: '12', end: '18' },
            Evening: { start: '18', end: '21' },
            Night: { start: '21', end: '24' },
            Anytime: { start: '00', end: '24' },
         },
         daysOption: [],
         weeksOption: [],
         showAvailTime : false
      }
   }, 
   computed: {
      buttonText() {
         if (this.data.timeline==="Day") {
            return this.currentIndex >= this.days.length - 1 ? 'Finish' : 'Add';    
         }
         else {
            return this.currentIndex >= this.dates.length - 1 ? 'Finish' : 'Add';
         }
      },
   },
   created() {
      if (this.data.timeline==="Day") {
         this.showAvailTime = true
      }
      this.calculDates()
      this.calculTimes()
   },
   methods : {
      showDay() {
         if (this.data.timeline==='Month') {
            return this.week!=="" && this.week!==null
         }
         else {
            return this.data.timeline!=='Day'
         }
      },
      calculDates() {
         this.days = []
         this.dates = []
         this.currentIndex = 0
         const startDateObj = new Date(this.data.startDate);
         const endDateObj = new Date(this.data.endDate);
         const timeDiff = Math.abs(endDateObj.getTime() - startDateObj.getTime());
         const totalDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
         var total = null
      switch (this.data.timeline) {
         case 'Week':
               const totalWeeks = Math.ceil(totalDays / 7);
               total = this.data.dedicatedTime / totalWeeks
               let startDate = new Date(startDateObj);
               const endDate = new Date(endDateObj);
               let dates = []
               while (startDate <= endDate) {
                  let endDateOfWeek = new Date(startDate);
                  endDateOfWeek.setDate(endDateOfWeek.getDate() + 6);
                  if (endDateOfWeek > endDate) {
                     endDateOfWeek = endDate;
                  }
                  dates.push({
                     startDate: moment(startDate).format('YYYY-MM-DD'),
                     endDate: moment(endDateOfWeek).format('YYYY-MM-DD'),
                     days: this.getDays(startDate,endDateOfWeek)
                  });
                  startDate.setDate(startDate.getDate() + 7);
               }
               this.nbrTaches = totalWeeks
               this.dates = dates; 
               this.date = this.dates[0]
               this.daysOption =  this.date.days
               break;
         case 'Month':
               const totalMonths = (endDateObj.getFullYear() - startDateObj.getFullYear()) * 12 + (endDateObj.getMonth() - startDateObj.getMonth()) + 1;
               total = this.data.dedicatedTime / totalMonths
               let months = [];
               let currentDate = new Date(startDateObj);
               for (let i = 0; i < totalMonths; i++) {
                  let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                  let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                  if (lastDay > endDateObj) {
                     lastDay = endDateObj; 
                  }
                  months.push({
                     startDate: moment(firstDay).format('YYYY-MM-DD'),
                     endDate: moment(lastDay).format('YYYY-MM-DD'),
                     weeks : this.getWeeks(firstDay, lastDay)
                  });
                  currentDate.setMonth(currentDate.getMonth() + 1);
                  if (currentDate > endDateObj) {
                     break;
                  }
               }
               this.nbrTaches = totalMonths
               this.dates = months; 
               this.date = this.dates[0]
               this.getWeekDays(this.date)
               break;
         default:
               total = this.data.dedicatedTime / totalDays
               for (let date = startDateObj ; date <= endDateObj ; date.setDate(date.getDate() + 1)) {
                  this.days.push(moment(date).format('dddd YYYY-MM-DD'));
               }
               this.dateDays = this.days[0]
               this.nbrTaches = totalDays
               break;
         }
         this.hoursPer = total
         if (!Number.isInteger(total)) {
            this.hoursPer = Math.floor(total);
            if (this.hoursPer===0) {
               this.hoursPer = 1
            }
         }
      },
      changeAvail() {
         this.calculAvailTimes()
         this.showAvailTime = this.day ? true : false;
      },
   calculTimes() {
      let timeSlots = []; 
      const startTime = this.timings[this.data.time].start;
      const endTime = this.timings[this.data.time].end;
      const startData = moment(this.data.startDate, 'dddd, MMMM D, YYYY HH:mm').format('HH:mm')
      const endData = moment(this.data.endDate, 'dddd, MMMM D, YYYY HH:mm').format('HH:mm')
      let current = `${startTime.toString().padStart(2, '0')}:00`;
      const here = moment(current, 'HH:mm:ss').isBefore(moment(startData, 'HH:mm:ss')) ? startData : current
      while (current < endTime) {
         let nextTime = this.addHour(current);
         timeSlots.push({
            text : `${current} to ${nextTime}`,
            value : { start: current, end: nextTime }
         })
         current = this.addHalfHour(nextTime);
      }
      const filteredSlots = timeSlots
      // const filteredSlots = timeSlots.filter(slot => {
      //    const slotStartTime = moment(slot.value.start, 'HH:mm:ss');
      //    const slotEndTime = moment(slot.value.end, 'HH:mm:ss');
      //    const selectedStart = moment(startData, 'HH:mm:ss');
      //    const selectedEnd = moment(endData, 'HH:mm:ss');
      //    return (
      //       slotStartTime.isSameOrAfter(selectedStart) && slotEndTime.isSameOrBefore(selectedEnd)
      //    );
      // });
      this.fullTimes = filteredSlots
      this.calculAvailTimes()
   },
   calculAvailTimes() {
      axios.get("https://backendapinodejs.timecheckit.com/api/date?user=" + JSON.parse(localStorage.getItem("userData"))._id)
            .then((response) => {
               console.log("response",response)
               const unavailableTimes = response.data.dates.reduce((result, data) => {
                  if (this.isValidDate(data.startDate) && this.isValidDate(data.endDate)) {
                     const startDate = moment.utc(data.startDate).format('YYYY-MM-DD');
                     const endDate = moment.utc(data.endDate).format('YYYY-MM-DD');
                     const startTime = moment.utc(data.startDate).format('HH:mm');
                     const endTime = moment.utc(data.endDate).format('HH:mm');
                     const date = (this.data.timeline === "Day" ? this.dateDays : this.day)
                     if (moment(date, 'dddd YYYY-MM-DD').isBetween(startDate, endDate, null, '[]')) {
                        result.push({
                           start : startTime,
                           end : endTime
                        })
                     }
                  }
                  return result;
               }, []);
               console.log("unavailableTimes",unavailableTimes)
               if (unavailableTimes.length!==0) {
                 console.log("this.times3",this.fullTimes)
                  var freeTimes = this.fullTimes.filter(availableTime => {
                  return !unavailableTimes.some(unavailableTime => {
                     return (
                        (availableTime.value.start >= unavailableTime.start &&
                        availableTime.value.start < unavailableTime.end) ||
                        (availableTime.value.end > unavailableTime.start &&
                        availableTime.value.end <= unavailableTime.end) ||
                        (availableTime.value.start <= unavailableTime.start &&
                        availableTime.value.end >= unavailableTime.end)
                     );
                  });
                  }).map(availableTime => ({
                  text: `${availableTime.value.start} to ${availableTime.value.end}`,
                  value: { start: availableTime.value.start, end: availableTime.value.end }
                  }));

                  console.log("this.times1",freeTimes)
                  this.times = freeTimes
               }
               else {

                  console.log("this.times2",this.fullTimes)
                  this.times = this.fullTimes
               }
            })
            .catch((error) => {
                this.errorMessage = error.message;
            });
   },
   isValidDate(dateString) {
      const date = new Date(dateString);
      return !isNaN(date) && date instanceof Date && !isNaN(date.getTime()) && dateString.includes('T');
   },
   addHour(time) {
      let [hours, minutes] = time.split(":").map(Number);
      hours += 1;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
   },
   addHalfHour(time) {
      let [hours, minutes] = time.split(":").map(Number);
         if (minutes === 0) {
            minutes = 30;
         } else {
            hours += 1;
            minutes = 0;
         }
         return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
   },
      getNextHour(time) {
      let [hours, minutes] = time.split(':').map(Number);
      if (minutes === 0) {
        return `${hours + 1}:00`;
      } else {
        return `${hours}:${minutes + 30}`;
      }
    },
   changeDays() {
      if (this.week) {
         this.day = ""
         const start = new Date(this.week.value.startDate)
         const end = new Date(this.week.value.endDate)
         this.daysOption = this.getDays(start,end)
      }
      else {
         this.day = ""
      }
      this.showAvailTime = false
   },
   getDays(startDate,endDate) {
      const days = []
      const start = moment(startDate);
      const end = moment(endDate);
      while (start <= end) {
         days.push(start.format('dddd YYYY-MM-DD'));
         start.add(1, 'days');
      }
      return days
   },
   getWeeks(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate,);
      const weeks = [];
      let weekStartDate = moment(start);

      while (weekStartDate <= end) {
         const weekEndDate = moment(weekStartDate).endOf('week').isBefore(end) ? moment(weekStartDate).endOf('week') : end;
         weeks.push({
            week: weeks.length + 1,
            startDate: moment(weekStartDate).format('YYYY-MM-DD'),
            endDate: moment(weekEndDate).format('YYYY-MM-DD'),
         });
         weekStartDate.add(1, 'week');
      }
      return weeks
   },
   getWeekDays(date) {
      this.weeksOption = []
      date.weeks.forEach(element => {
         this.weeksOption.push({
            label : "Week "+element.week+" : "+element.startDate+" to "+element.endDate,
            value : {startDate : element.startDate , endDate : element.endDate}
         })
      })
   },
    add() {
      if (this.data.timeline==='Day') {
         this.showNextDays()
         this.calculAvailTimes()
      }
      else {
         this.showNext()
      }
    },
   showNextDays() {
      if (this.validateHours()) {
         const formattedDate = moment(this.dateDays, 'dddd YYYY-MM-DD').format('YYYY-MM-DD');
         if (this.show) {
            this.selectedTimes.forEach(time => {
               this.taches.push({
                  day : formattedDate,
                  startTime : time.start,
                  endTime : time.end,
                  isDone : false,
                  percentage : 0
               })         
            });
         }
         else {
            this.availTimes.forEach(time => {
               this.taches.push({
                  day : formattedDate,
                  startTime : time.start === "00:00" ? time.start : this.formatTime(time.start),
                  endTime : time.end === "00:00" ? time.end : this.formatTime(time.end),
                  isDone : false,
                  percentage : 0
               })      
            });     
         }
         if (this.currentIndex < this.days.length - 1) {
            this.currentIndex++;
            this.dateDays = this.days[this.currentIndex];
            this.selectedTimes = []
            this.availTimes = [
               {
                  start : "00:00",
                  end : "00:00"
               }
            ]
         } else {
            this.$emit('data', this.taches);
         }
      }
   },
   showNext() {
      if (this.validateHours()) {
         const formattedDate = moment(this.day, 'dddd YYYY-MM-DD').format('YYYY-MM-DD');
         if (this.show) {
            this.selectedTimes.forEach(time => {
               this.taches.push({
                  day : formattedDate,
                  startTime : time.start,
                  endTime : time.end,
                  isDone : false,
                  percentage : 0
               })         
            });
         }
         else {
            this.availTimes.forEach(time => {
               this.taches.push({
                  day : formattedDate,
                  startTime : time.start === "00:00" ? time.start : this.formatTime(time.start),
                  endTime : time.end === "00:00" ? time.end : this.formatTime(time.end),
                  isDone : false,
                  percentage : 0
               })         
            });    
         }
         if (this.currentIndex < this.dates.length - 1) {
            this.currentIndex++;
            this.date = this.dates[this.currentIndex];
            if (this.data.timeline==="Week") {
               this.daysOption = this.date.days
            }
            if (this.data.timeline==="Month") {
               this.getWeekDays(this.date)
            }
            this.selectedTimes = []
            this.availTimes = [
               {
                  start : "00:00",
                  end : "00:00"
               }
            ]
            this.day = ""
            this.week = ""
            this.showAvailTime = false
         } else {
            this.$emit('data', this.taches);
         }
      }
   },
   validateHours() {
      if (this.data.timeline==="Month" && this.week==="" || this.week===null) {
         this.$toast({
            component: ToastificationContent,
            props: {
              title: `You need to choose a week to continue`,
              icon: "EditIcon",
              variant: "warning",
            },
         });
      }
      else if (this.data.timeline!=="Day" && this.day==="" || this.day===null) {
         this.$toast({
            component: ToastificationContent,
            props: {
              title: `You need to choose a day to continue`,
              icon: "EditIcon",
              variant: "warning",
            },
         });
      }
      else if (this.show && this.times.length!== 0 && this.selectedTimes.length===0) {
         this.$toast({
            component: ToastificationContent,
            props: {
              title: `You need to choose atleast one option to continue`,
              icon: "EditIcon",
              variant: "warning",
            },
         });
      }  
      if (this.data.timeline==="Day") {
         return this.show && this.times.length!== 0  ? this.selectedTimes.length!==0 : true
      }
      else if (this.show && this.data.timeline==="Week") {
         if (this.times.length!== 0) {
            return this.selectedTimes.length!==0 && this.day!=="" && this.day!==null
         }
         else {
            return this.day!=="" && this.day!==null  
         } 
      }
      else if (this.show===false && this.data.timeline==="Week") {
         return this.day!=="" && this.day!==null   
      }
      else {
         if (this.show) {
            if (this.times.length!== 0) {
               return this.selectedTimes.length!==0 && this.day!=="" && this.day!==null && this.week!=="" && this.week!==null
            }
            else {
               return this.day!=="" && this.day!==null && this.week!=="" && this.week!==null
            }
         }
         else {
            return this.day!=="" && this.day!==null && this.week!=="" && this.week!==null
         }
      }
   },
   addManAuto() {
      this.show = !this.show
      this.calculDates()
      this.calculTimes()
      this.taches = []
      this.selectedTimes = []
      this.availTimes = [
         {
            start : "00:00",
            end : "00:00"
         }
      ]
      this.day = ""
      this.week = ""
   },
   Addtimeline() {
      this.availTimes.push({
         start : "00:00",
         end : "00:00"
      })
   },
   Minustimeline(index) {
      this.availTimes.splice(index,1);    
   },
   finish() {
      this.$emit('data', this.taches);
   },
   returnStep1() {
      this.$emit('return');
   },
   formatTime(time) {
         const date = new Date(time);
         var hours = date.getUTCHours().toString().padStart(2, '0');
         let intValue = parseInt(hours, 10) + 1;
         if (intValue === 24) {
         intValue = 0;
         }
         hours = intValue.toString().padStart(hours.length, "0");
         const minutes = date.getUTCMinutes().toString().padStart(2, '0');
         return `${hours}:${minutes}`;
   }
} 
}
</script>

<style lang="scss">
@import "../../assets/time-scroll-selector.scss";
.prop-obj .custom-control {
   margin-bottom: 15px;
}
.vdatetime-input {
   background-color: white;
   color:black;
   padding: 0.438rem 1rem;
   border : 1px solid #d8d6de !important;
   border-radius: 0.357rem;
   text-align: center;
}
.add-time-icon,.remove-time-icon {
   width: 1.7rem;
   height: 1.7rem;
   cursor: pointer;
}
.add-time-icon {
   color: rgb(0, 170, 0);
}
.remove-time-icon {
   color: rgb(228, 66, 66);
}
</style>