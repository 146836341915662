<template>
    <div>
      <div
         class="d-flex justify-content-end align-items-center content-sidebar-header px-2 py-1"
      >
         <b-button
            size="sm"
            :variant="'outline-danger'"
            @click="$emit('delete-subObj', tache)"
         >
         <feather-icon
            icon="TrashIcon"
            class="cursor-pointer"
         />
             {{ $t('Delete') }}
         </b-button>
        </div>
      <b-row>
  <b-col cols="12" v-if="obj.timeline==='Day'">
    <b-form-group :label="$t('Date')" label-for="date">
      <b-form-input
        id="date"
        :placeholder="$t('Date')"
        v-model="dateDays"
        readonly
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" v-if="obj.timeline!=='Day'">
    <b-form-group :label="$t('Start_Date')" label-for="startDate">
      <b-form-input
        id="startDate"
        :placeholder="$t('Start_Date')"
        v-model="date.startDate"
        readonly
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" v-if="obj.timeline!=='Day'">
    <b-form-group :label="$t('End_Date')" label-for="endDate">
      <b-form-input
        id="endDate"
        :placeholder="$t('End_Date')"
        v-model="date.endDate"
        readonly
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" v-if="obj.timeline==='Month'">
    <b-form-group :label="$t('Week')" label-for="week">
      <v-select
        id="week"
        :placeholder="$t('Week')"
        v-model="week"
        :options="weeksOption"
        @input="changeDays"
      />
    </b-form-group>
  </b-col>
  <b-col cols="12" :lg="obj.timeline === 'Month' ? '6' : '12'" v-if="showDay()">
    <b-form-group :label="$t('Day')" label-for="day">
      <v-select
        id="day"
        :placeholder="$t('Day')"
        v-model="tache.day"
        :options="daysOption"
      />
    </b-form-group>
  </b-col>
</b-row>

      <b-row class="mt-2 mb-2">
  <b-col cols="12">
    <b-form-group :label="$t('Available_Time')">
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" sm="12">
    <b-form-group :label="$t('From')">
      <datetime type="time" v-model="tache.startTime"></datetime>
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="6" sm="12">
    <b-form-group :label="$t('To')">
      <datetime type="time" v-model="tache.endTime"></datetime>
    </b-form-group>
  </b-col>
</b-row>
<b-row>
  <b-col cols="12" lg="12" class="mb-1">
    <h5>{{$t('Percentage_Task')}}</h5>
  </b-col>
  <b-col cols="12" lg="5" class="mt-1">
    <b-form-group label="" label-for="percentageRange">
      <b-form-input id="percentageRange" type="range" v-model="tache.percentage"></b-form-input>
    </b-form-group>
  </b-col>
  <b-col cols="12" lg="2" class="mt-1 text-center">
    <span>{{$t('Or')}}</span>
  </b-col>
  <b-col cols="12" lg="5">
    <b-form-group label="" label-for="percentageNum" :state="getValidationState(tache.percentage)">
      <b-form-input
        :placeholder="$t('Enter_Number')"
        id="percentageNum"
        type="number"
        max="100"
        v-model="tache.percentage"
      ></b-form-input>
      <b-form-invalid-feedback :state="getValidationState(tache.percentage)">
        {{$t('Percentage_Must_Be_Between')}}
      </b-form-invalid-feedback>
    </b-form-group>
  </b-col>
</b-row>
    </div>
</template>

<script>
import {
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
   BButton,
   BFormCheckboxGroup,
   BFormDatepicker,
   BFormInvalidFeedback
} from "bootstrap-vue";
import moment from 'moment';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Datetime } from "vue-datetime";
import vSelect from "vue-select";
import axios from "axios";

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormCheckboxGroup,
        BFormDatepicker,
        Datetime,
        vSelect,
        BFormInvalidFeedback
    },
    props: {
        obj: Object,
        id: String
    },
    data() {
        return {
            day : "",
            week : "",
            show : true,
            taches : [],
            days : [],
            dates : [],
            times : [],
            dateDays : "",
            date : {},
            currentIndex: 0,
            hoursPer : null,
            nbrTaches : null,
            selectedTimes : [],
            maxDate : null,
            minDate : null,
            daysOption: [],
            weeksOption: [],
            perRange : null,
            perNum : null,
            tache : {
               start : "",
               end : "",
               percentage : 0
            }
        }
    },
    mounted() {
        axios.get("https://backendapinodejs.timecheckit.com/api/subObjectifs?id="+ this.id)
        .then((response) => {
            this.tache = response.data.data;
            this.tache.day = moment(this.tache.day).format('dddd YYYY-MM-DD')
            if (this.obj.timeline==="Day") {
               this.dateDays = this.tache.day
            }
            else {
               this.calculDates()
            }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    methods : {
      getValidationState(percentage) {
         if (percentage === null || percentage === '' || isNaN(percentage) || percentage < 0 || percentage > 100) {
         return false; // Invalid state
         } else {
         return true; // Valid state
         }
      },
      update(bvModalEvent) {
            this.tache.percentage = parseInt(this.tache.percentage)
            this.tache.isDone = this.tache.percentage === 100 ? true : false
            this.tache.day = moment(this.tache.day, 'dddd YYYY-MM-DD').format('YYYY-MM-DD');
            this.tache.startTime = this.isValidTimeFormat(this.tache.startTime) ? this.tache.startTime : this.formatTime(this.tache.startTime)
            this.tache.endTime = this.isValidTimeFormat(this.tache.endTime) ? this.tache.endTime : this.formatTime(this.tache.endTime)
            if(this.validateHours() && this.getValidationState(this.tache.percentage)) {
               this.$emit('objective',this.tache);
            }
            else {
               bvModalEvent.preventDefault()
            }
      },
      isValidTimeFormat(time) {
         const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
         return pattern.test(time);
      },
      showDay() {
         if (this.obj.timeline==='Month') {
            return this.week!=="" && this.week!==null
         }
         else {
            return this.obj.timeline!=='Day'
         }
      },
      calculDates() {
        const startDateObj = new Date(this.obj.startDate);
        const endDateObj = new Date(this.obj.endDate);
        const timeDiff = Math.abs(endDateObj.getTime() - startDateObj.getTime());
        const totalDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
        var total = null
        var result = {}
        switch (this.obj.timeline) {
            case 'Week':
                const totalWeeks = Math.ceil(totalDays / 7);
                total = this.obj.dedicatedTime / totalWeeks
                let startDate = new Date(startDateObj);
                const endDate = new Date(endDateObj);
                let dates = []
                while (startDate <= endDate) {
                let endDateOfWeek = new Date(startDate);
                endDateOfWeek.setDate(endDateOfWeek.getDate() + 6);
                if (endDateOfWeek > endDate) {
                    endDateOfWeek = endDate;
                }
                dates.push({
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: moment(endDateOfWeek).format('YYYY-MM-DD'),
                    days: this.getDays(startDate,endDateOfWeek)
                });
                startDate.setDate(startDate.getDate() + 7);
                }
                this.nbrTaches = totalWeeks
                this.dates = dates; 
               result = this.dates.find(item => {
                  const day = moment(this.tache.day).format('YYYY-MM-DD')
                  return day >= item.startDate && day <= item.endDate;
               });
               this.date = result ? result : {}
                this.daysOption =  this.date.days
                break;
            case 'Month':
                const totalMonths = (endDateObj.getFullYear() - startDateObj.getFullYear()) * 12 + (endDateObj.getMonth() - startDateObj.getMonth()) + 1;
                total = this.obj.dedicatedTime / totalMonths
                let months = [];
                let currentDate = new Date(startDateObj);
                for (let i = 0; i < totalMonths; i++) {
                let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                if (lastDay > endDateObj) {
                    lastDay = endDateObj; 
                }
                months.push({
                    startDate: moment(firstDay).format('YYYY-MM-DD'),
                    endDate: moment(lastDay).format('YYYY-MM-DD'),
                    weeks : this.getWeeks(firstDay, lastDay)
                });
                currentDate.setMonth(currentDate.getMonth() + 1);
                if (currentDate > endDateObj) {
                    break;
                }
                }
                this.nbrTaches = totalDays
                this.dates = months; 
                result = this.dates.find(item => {
                  const day = moment(this.tache.day).format('YYYY-MM-DD')
                  return day >= item.startDate && day <= item.endDate;
               });
                this.date = result ? result : {}
                this.getWeekDays(this.date)
                if (this.week) {
                  const start = new Date(this.week.value.startDate)
               const end = new Date(this.week.value.endDate)
               this.daysOption = this.getDays(start,end)
                }
                break;
            default:
                total = this.obj.dedicatedTime / totalDays
                for (let date = startDateObj ; date <= endDateObj ; date.setDate(date.getDate() + 1)) {
                this.days.push(moment(date).format('YYYY-MM-DD'));
                }
                this.dateDays = this.days[0]
                this.nbrTaches = totalDays
                break;
        }
      this.hoursPer = total
      if (!Number.isInteger(total)) {
         this.hoursPer = Math.floor(total);
      }
   },
   changeDays() {
      if (this.week) {
         this.tache.day = ""
         const start = new Date(this.week.value.startDate)
         const end = new Date(this.week.value.endDate)
         this.daysOption = this.getDays(start,end)
      }
      else {
         this.tache.day = ""
      }
   },
   getDays(startDate,endDate) {
      const days = []
      const start = moment(startDate);
      const end = moment(endDate);
      while (start <= end) {
         days.push(start.format('dddd YYYY-MM-DD'));
         start.add(1, 'days');
      }
      return days
   },
   getWeeks(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate,);
      const weeks = [];
      let weekStartDate = moment(start);

      while (weekStartDate <= end) {
         const weekEndDate = moment(weekStartDate).endOf('week').isBefore(end) ? moment(weekStartDate).endOf('week') : end;
         weeks.push({
            week: weeks.length + 1,
            startDate: moment(weekStartDate).format('YYYY-MM-DD'),
            endDate: moment(weekEndDate).format('YYYY-MM-DD'),
         });
         weekStartDate.add(1, 'week');
      }
      return weeks
   },
   getWeekDays(date) {
      this.weeksOption = []
      date.weeks.forEach(element => {
         this.weeksOption.push({
            label : "Week "+element.week+" : "+element.startDate+" to "+element.endDate,
            value : {startDate : element.startDate , endDate : element.endDate}
         })
      })
      const result = this.weeksOption.find(item => {
         const day = moment(this.tache.day).format('YYYY-MM-DD')
         return day >= item.value.startDate && day <= item.value.endDate;
      });
      this.week = result ? result : {}
   },
   validateHours() {
      if (this.obj.timeline==="Month" && this.week==="" || this.week===null) {
         this.$toast({
            component: ToastificationContent,
            props: {
              title: `You need to choose a week to continue`,
              icon: "EditIcon",
              variant: "warning",
            },
         });
      }
      if (this.obj.timeline!=="Day" && this.tache.day==="" || this.tache.day===null) {
         this.$toast({
            component: ToastificationContent,
            props: {
              title: `You need to choose a day to continue`,
              icon: "EditIcon",
              variant: "warning",
            },
         });
      }
      if (this.obj.timeline!=="Day") {
         if (this.obj.timeline==="Month") {
            return (this.tache.day!=="" && this.tache.day!==null) && (this.week!=="" && this.week!==null)  
         }     
         else {
            return this.tache.day!=="" && this.tache.day!==null
         }
      }
      else {
         return true
      }
   },
   formatTime(time) {
         const date = new Date(time);
         var hours = date.getUTCHours().toString().padStart(2, '0');
         let intValue = parseInt(hours, 10) + 1;
         if (intValue === 24) {
         intValue = 0;
         }
         hours = intValue.toString().padStart(hours.length, "0");
         const minutes = date.getUTCMinutes().toString().padStart(2, '0');
         return `${hours}:${minutes}`;
   }
    }
}
</script>