<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit" class="objectif-page">
    <div
      class="body-content-overlay"
       :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="dataObjectif"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
        <li class="mt-2" v-if="dataToDisplay.length===0">{{ $t('There_are_no_objectives_yet') }}</li>
          <li 
            v-if="dataToDisplay.length!==0"
            v-for="(obj, index) in dataToDisplay"
            :key="index"
            class="todo-item"
            :class="{ completed: obj.isAchieved }"
          >
            <!-- @click="handleTaskClick(obj)" -->

            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper" style="align-items: flex-start">
                <div class="todo-item-action display-responsive-none" style="width: 100%">
                  <div class="badge-wrapper" style="padding-top: 30px">
                    <div style="padding-right: 15px">
                      <feather-icon
                        icon="Edit2Icon"
                        size="21"
                        class="cursor-pointer"
                        v-b-modal="'updateObjective'"
                        @click="sendUpdateData(obj._id)"
                      />
                    </div>
                    <div style="width: 100%" class="">
                      <b-progress
                        :max="max"
                        class="progress-bar-info progress-bar-info-objectif"
                        show-value
                      >
                        <b-progress-bar
                        :value="mainProgressBarValue(obj)"
                        variant="primary"
                        />
                      </b-progress>
                    </div>
                  </div>
              </div>
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    :checked="obj.isDone"
                    @change="updateCompleteObjective(obj)"
                  />
                  <!-- @change="updateTaskIsCompleted(obj)" -->
                  <app-collapse
                    id="collapse-objectif"
                    accordion
                    type="margin"
                    class="mt-2"
                  >
                    <app-collapse-item :title="obj.objectifName">
                      <div v-if="obj.subObjectifs.length <= 0">No sub tasks</div>
                      <div v-else>
                        <div
                          v-for="(subTask, i) in obj.subObjectifs"
                          :key="i"
                          class="sub-objectif-tasks-title"
                        >
                          <li
                            class="inline"
                            style="
                              width: 100%;
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div class="todo-title-area">
                              <div class="title-wrapper mr-5">
                                <b-form-checkbox
                                  v-model="subTask.isDone"
                                  @change="
                                    updateCompletedSubTasks(subTask, obj)
                                  "
                                />
                                <span class="todo-title"
                                  >{{ formatSubTaskDate(subTask.day) }}
                                  {{ subTask.startTime }} to
                                  {{ subTask.endTime }}
                                </span>
                              </div>
                            </div>
                            <div class="todo-item-action">
                              <div class="badge-wrapper mr-1">
                                <div style="padding-right: 15px">
                                  <feather-icon
                                    v-b-modal="'updateObjProp'"
                                    icon="Edit2Icon"
                                    size="21"
                                    class="cursor-pointer"
                                    @click="sendUpdateDataProp(obj , subTask._id)"
                                  />
                                </div>
                                <div style="width: 150px">
                                  <b-progress
                                    :max="max"
                                    class="progress-bar-info"
                                    show-value
                                  >
                                    <b-progress-bar
                                      variant="primary"
                                      :value="subTask.percentage"
                                    />
                                  </b-progress>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div>
                    </app-collapse-item>
                  </app-collapse>
                </div>
              </div>
            <div class="todo-item-action display-none">
                <div class="badge-wrapper mr-1" style="padding-top: 30px">
                  <div style="padding-right: 15px">
                    <feather-icon
                      icon="Edit2Icon"
                      size="21"
                      class="cursor-pointer"
                      v-b-modal="'updateObjective'"
                      @click="sendUpdateData(obj._id)"
                    />
                  </div>
                  <div style="width: 200px" class="">
                    <b-progress
                      :max="max"
                      class="progress-bar-info progress-bar-info-objectif"
                      show-value
                    >
                      <b-progress-bar
                      :value="mainProgressBarValue(obj)"
                      variant="primary"
                      />
                    </b-progress>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </draggable>
      </vue-perfect-scrollbar>
    </div>

    <b-modal
      size="lg"
      ref="updateObjective"
      id="updateObjective"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      title="Update Goals"
      no-close-on-backdrop
      ok-title="Next"
      @ok="updateObjectives"
    >
    <updateObj ref="updateObj" :data="dataToSend" :id="updateData" @objective="openSecondModal" @delete-obj="removeObjective"
    @restore-obj="restoreObjective"
    @complete-task="updateCompleteObjective"></updateObj>
    </b-modal>

    <b-modal
      size="lg"
      ref="upObjProp"
      id="updateObjProp"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      title="Update sub Goals tasks"
      no-close-on-backdrop
      ok-title="Save"
      @ok="updatePropObjective"
    >
      <updateObjProp ref="updateObjProp" :obj="updateDataProp" :id="updateDataProp2" @objective="updateProp" 
      @delete-subObj="deleteSubObj"></updateObjProp>
    </b-modal>
    <b-modal
      class="model-propostion"
      size="lg"
      ref="secondModal"
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      title="Add Goals tasks"
      no-close-on-backdrop
      shadow
      backdrop
      no-header
      right
      hide-footer
    >
    <propObj ref="addProp" :data="dataToSend" @data="addPropObj" @return="openFirstModal"></propObj>
    </b-modal>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <Objectives-left-sidebar
        @change-display="filterData"
        :class="{ show: mqShallShowLeftSidebar }"
      />
    </portal>
  </div>
</template>

<script>
import { BProgress, BProgressBar } from "bootstrap-vue";
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BCollapse,
  BButton,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import ObjectivesLeftSidebar from "./ObjectivesLeftSidebar.vue";
import updateObj from "./UpdateObjective.vue"
import propObj from "./PropositionObjective.vue"
import updateObjProp from "./UpdatePropositionObjective.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { eventBus } from '../../main';
import moment from 'moment';
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";

export default {
  data() {
    return {
      subTaskToUpdate:{},
      dataFromObjectif:{},
      dataObjectif: [],
      completedToDisplay: [],
      deletedToDisplay: [],
      activeObjectif: [],
      dataToDisplay: [],
      updateData: null,
      updateDataProp: null,
      updateDataProp2 : null,
      max: 100,
      mqShallShowLeftSidebar: false,
      dataToSend : {},
      perfectScrollbarSettings : {
        maxScrollbarLength: 150,
      }
    };
  },

  components: {
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BProgress,
    BProgressBar,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    BButton,
    VuePerfectScrollbar,
    ToastificationContent,
    // App SFC
    ObjectivesLeftSidebar,
    BCollapse,
    updateObj,
    updateObjProp,
    propObj,
  },
  created() {
    // this.mqShallShowLeftSidebar=useResponsiveAppLeftSidebarVisibility();
    this.getObjectif();
    eventBus.$on('call-function', this.getObjectif);
  },
  beforeDestroy() {
    eventBus.$off('call-function', this.getObjectif);
  },
  computed : {
    mainProgressBarValue() {
      return (obj) => {
        const totalPercentage = obj.subObjectifs.reduce((total, task) => total + task.percentage, 0);
        const averagePercentage = totalPercentage / obj.subObjectifs.length;
        return averagePercentage;
      };
    }   
  },
  methods: {
    getObjectif() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/objectifs?user=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          console.log(response.data.data)
          this.dataObjectif = response.data.data;
          this.filterData();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    filterData() {
      console.log("data obj",this.dataObjectif)
      this.completedToDisplay = [];
      this.deletedToDisplay = [];
      this.activeObjectif = [];
      this.dataObjectif.forEach((element) => {
        if (!element.isDeleted && !element.isDone)
        this.activeObjectif.push(element);
      });
      this.dataObjectif.forEach((element) => {
        if (element.isDeleted) this.deletedToDisplay.push(element);
      });
      this.dataObjectif.forEach((element) => {
        if (element.isDone && !element.isDeleted) this.completedToDisplay.push(element);
      });

      if (window.location.pathname.split("/")[3] == "deleted") {
        this.dataToDisplay = this.deletedToDisplay;
      } else if (window.location.pathname.split("/")[3] == "done") {
        this.dataToDisplay = this.completedToDisplay;
      } else {
        this.dataToDisplay = this.activeObjectif;
      }
    },
    formatSubTaskDate(date) {
      return moment(date, 'YYYY-MM-DD').format('dddd YYYY-MM-DD');
    },
    updateCompletedSubTasks(sub, obj) {
      sub.percentage = sub.isDone ? 100 : 0;
      obj.isDone = obj.subObjectifs.every((task) => task.isDone);
      const index = obj.subObjectifs.findIndex(item => item._id === sub._id);
      obj.subObjectifs[index] = sub;
      obj.tags.forEach((element, index) => {
        if (element.value) {
          obj.tags[index] = element.value;
        }
      });
      axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/objectifs/" + obj._id,
          obj
        )
        .then((response) => {
          this.filterData();
        })
        .catch((error) => {
          console.error("There was an error!", error);
      });
      axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/subObjectifs/" + sub._id,
          sub
        )
        .then((response) => {
          console.log("sub",response)
           this.filterData();
        })
        .catch((error) => {
          console.error("There was an error!", error);
      });
    },
    updateCompleteObjective(obj) {
      obj.isDone = !obj.isDone
      obj.tags.forEach((element, index) => {
        if (element.value) {
          obj.tags[index] = element.value;
        }
      });
      obj.subObjectifs.forEach(task => {
        task.isDone = obj.isDone
        task.percentage = obj.isDone ? 100 : 0
        axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/subObjectifs/" + task._id,
          task
        )
        .then((response) => {
           console.log("sub",response)
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      })
      axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/objectifs/" + obj._id,
          obj
        )
        .then((response) => {
          this.$bvModal.hide('updateObjective');
          this.getObjectif();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    sendUpdateData(id) {
      this.dataToSend = {}
      this.updateData = id
    },
    sendUpdateDataProp(obj,id2) {
      this.updateDataProp = obj
      this.updateDataProp2 = id2
    },
    updateObjectives(bvModalEvent) {
      this.$refs.updateObj.update(bvModalEvent)
    },
    updatePropObjective(bvModalEvent) {
      this.$refs.updateObjProp.update(bvModalEvent)
    },
    openSecondModal(data) {
      this.dataToSend = data
      this.$refs.secondModal.show();
    },
    openFirstModal() {
      this.$refs.updateObjective.show();
      this.$refs.secondModal.hide();
    },
    addPropObj(data) {
      const obj = JSON.parse(JSON.stringify(this.dataToSend));
      obj.startDate = moment(this.dataToSend.startDate, 'dddd, MMMM D, YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      obj.endDate = moment(this.dataToSend.endDate, 'dddd, MMMM D, YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      obj.tags.forEach((element, index) => {
        if (element.value) {
          obj.tags[index] = element.value;
        }
      });
      const filteredArray = this.dataToSend.subObjectifs.filter((item) => item.isDone);
      data.forEach(task => {
        task.objectif = obj._id
          axios
          .post(
            "https://backendapinodejs.timecheckit.com/api/subObjectifs",task
          )
          .then((response) => {
          })
          .catch((error) => {
          });
      })
      filteredArray.forEach(task => {
          axios
          .delete(
            "https://backendapinodejs.timecheckit.com/api/subObjectifs/" + task._id
          )
          .then((response) => {
          })
          .catch((error) => {
          });
      })
      axios
      .put(
        "https://backendapinodejs.timecheckit.com/api/objectifs/" + obj._id,obj
      )
      .then((response) => {
        this.$refs.secondModal.hide();
        this.getObjectif();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Objective Updated`,
            icon: "EditIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
      });
    },
    updateProp(subObj) {
      axios
      .put(
        "https://backendapinodejs.timecheckit.com/api/subObjectifs/" + subObj._id,subObj
      )
      .then((response) => {
        this.$bvModal.hide('upObjProp');
        this.objectifIsDone(subObj.objectif)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Sub Objective Updated`,
            icon: "EditIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
      });
    },
    objectifIsDone(id) {
      axios
      .get(
      "https://backendapinodejs.timecheckit.com/api/objectifs?id="+ id
      )
      .then((response) => {
        const obj = response.data.data
        obj.isDone = obj.subObjectifs.every((task) => task.isDone);
        obj.tags.forEach((element, index) => {
          if (element.value) {
            obj.tags[index] = element.value;
           }
        });
        axios.put(
          "https://backendapinodejs.timecheckit.com/api/objectifs/" + obj._id,obj
        )
        .then((response) => {
          this.getObjectif();
        })
        .catch((error) => {
          console.error("There was an error!", error);
      });
      })
      .catch((error) => {
      });
    },
    deleteSubObj(subObj) {
      axios
      .delete(
      "https://backendapinodejs.timecheckit.com/api/subObjectifs/" + subObj._id
      )
      .then((response) => {
        console.log("sub",response)
        this.$refs.upObjProp.hide();
        this.objectifIsDone(subObj.objectif)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Sub Objective Deleted`,
            icon: "EditIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
      });
    },
    removeObjective(obj) {
      if (window.location.pathname.split("/")[3] == "deleted") {
        this.$swal({
        title: "Are you sure you want to delete this goal permanently?",
        text: "You can't undo this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm !",
        cancelButtonText: "Decline !",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
          .delete(
            "https://backendapinodejs.timecheckit.com/api/objectifs/" + obj._id
          )
          .then((response) => {
            this.$bvModal.hide('updateObjective');
            this.getObjectif();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Goal deleted`,
                icon: "UserIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error while deleting`,
                  icon: "UserIcon",
                  variant: "danger",
                },
            });
          });
        }
      });
      } else {
          obj.isDeleted = true;
          obj.tags.forEach((element, index) => {
            if (element.value) {
              obj.tags[index] = element.value;
            }
          });
          axios
          .put(
            "https://backendapinodejs.timecheckit.com/api/objectifs/" + obj._id,obj
          )
          .then((response) => {
            this.$bvModal.hide('updateObjective');
            this.getObjectif();
          })
          .catch((error) => {
          });
      }
    },
    restoreObjective(obj) {
      obj.isDeleted = false;
      obj.tags.forEach((element, index) => {
        if (element.value) {
          obj.tags[index] = element.value;
        }
      });
      axios
          .put(
            "https://backendapinodejs.timecheckit.com/api/objectifs/" + obj._id,obj
          )
          .then((response) => {
            this.$bvModal.hide('updateObjective');
            this.getObjectif();
          })
          .catch((error) => {
          });
    }
  },
};
</script>

<style lang="scss">
.eh {
  background-color: red !important;
  width: 800px;
}
.todo-application .content-area-wrapper .content-right {
  width: 100% !important;
}
[dir] .collapse-margin .card {
  box-shadow: 0 4px 24px 0 transparent !important;
}
[dir="ltr"] [class*="collapse-"] .card .card-header {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 30px;
  padding-bottom: 0;
}
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

.todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
.progress-bar-info {
  height: 20px;
}
.progress-bar-info-objectif div {
  background: linear-gradient(118deg, #9600ff, #00cfe8);
}
.sub-objectif-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebe9f1;
}
.sub-objectif-tasks-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebe9f1;
}
.sub-objectif-tasks-title:last-of-type {
  border-bottom: none !important;
  padding-bottom: 0px;
}
.sub-objectif-tasks-title li {
  display: inline-flex;
}
.dark-layout .collapse-margin .card{
  box-shadow: none !important;
}
.dark-layout .collapse-margin .card span{
  color: #fff !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
