<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar" style="width: 200px">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-modal="'addObjective'"
              @click="dataToSend = {}"
            >
{{ $t('Add_Objective') }}            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('change-display')"
              >
                <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
    <b-modal
      ref="firstModal"
      size="lg"
      id="addObjective"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      title="Add Goals"
      no-close-on-backdrop
      ok-title="Next"
      @ok="addObj"
    >
    <addObj ref="addObjective" :data="dataToSend" @objective="openSecondModal"></addObj>
    </b-modal>
    <b-modal
      class="model-propostion"
      size="lg"
      ref="secondModal"
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      title="Add Goals tasks"
      no-close-on-backdrop
      shadow
      backdrop
      no-header
      right
      hide-footer
    >
    <propObj ref="addProp" :data="dataToSend" @data="addPropObj" @return="openFirstModal"></propObj>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import addObj from "./AddObjective.vue"
import propObj from "./PropositionObjective.vue"
import axios from "axios";
import { eventBus } from '../../main';
import moment from 'moment';

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    addObj,
    propObj
  },
  data() {
    return {
      dataToSend : {},
      taches : {}
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const taskFilters = [
      { title: "My Objective",
       icon: "MailIcon", 
       route: { name: "apps-objectifs",params: { filter: "all" } }
       },
      {
        title: "Completed",
        icon: "StarIcon",
        route: { name: "apps-obj-filter", params: { filter: "done" } },
      },
      {
        title: "Deleted",
        icon: "TrashIcon",
        route: { name: "apps-obj-filter", params: { filter: "deleted" } },
      },
    ];

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
    };
  },
  methods: {
    addObj(bvModalEvent) {
      this.$refs.addObjective.add(bvModalEvent)
    },
    openSecondModal(data) {
      this.dataToSend = data
      this.$refs.secondModal.show();
    },
    openFirstModal() {
      this.$refs.firstModal.show();
      this.$refs.secondModal.hide();
    },
    addPropObj(data) {
      this.dataToSend.taches = data
      const objectData = JSON.parse(JSON.stringify(this.dataToSend));
      objectData.startDate = moment(this.dataToSend.startDate, 'dddd, MMMM D, YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      objectData.endDate = moment(this.dataToSend.endDate, 'dddd, MMMM D, YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      axios.post("https://backendapinodejs.timecheckit.com/api/objectifs", objectData)
        .then((response) => {
          this.$refs.secondModal.hide();
          eventBus.$emit('call-function')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Objective Added`,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
      });
    }
  }
};
</script>

<style></style>
