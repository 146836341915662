<template>
    <div>
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
            <b-button
            size="sm"
            :variant="
              objectif.isDone ? 'outline-success' : 'outline-secondary'
            "
            @click="$emit('complete-task', objectif);"
          >
            {{ objectif.isDone ? "Completed" : "Mark as completed" }}
            </b-button>
            <div>
            <b-button
            variant="outline-warning"
            v-if="objectif.isDeleted"
            class="mr-3"
            size="sm"
            @click="$emit('restore-obj', objectif)">Restore
            </b-button>
            <b-button
              size="sm"
              :variant="
                objectif.isDeleted ? 'outline-danger' : 'outline-secondary'
              "
              @click="$emit('delete-obj', objectif)"
            >
                <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer"
                />
                {{ objectif.isDeleted ? "Delete" : "Mark as Deleted" }}
            </b-button>
        </div>
        </div>
        <b-row
            class="p-2"
        >
            <b-col cols="12" class="mb-1 text-center">
                <p style="color:red">
                    <span style="text-decoration: underline;">Important</span> : Updating the goal will require adding new tasks.
                </p>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('Title')" label-for="task-title" invalid-feedback="Title is required" :state="state1">
                    <b-form-input
                    id="task-title"
                    v-model="objectif.objectifName"
                    trim
          :placeholder="$t('Objective_Title')"
                    :state="state1"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group
                label="Start Date"
                label-for="start-date"
                invalid-feedback="Start Date is required"
                :state="state2"
                >
                <flat-pickr
                    v-model="objectif.startDate"
                    placeholder="Date"
                    class="form-control"
                    :state="state2"
                    @input="changeTimeline()"
                    :config="{ enableTime: true, dateFormat: 'l, F j, Y H:i', maxDate: maxStartDate, maxTime: maxStartTime }"/>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group
                label="End Date"
                label-for="end-date"
                invalid-feedback="End Date is required"
                :state="state3"
                >
                <flat-pickr
                    v-model="objectif.endDate"
                    placeholder="Date"
                    class="form-control"
                    :state="state3"
                    @input="changeTimeline()"
                    :config="{ enableTime: true, dateFormat: 'l, F j, Y H:i', minDate: minEndDate, minTime: minEndTime }"/>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
               :label="$t('Dedicated_time_hours')"
                label-for="timeline-time"
                :invalid-feedback="invalidTimeline"
                :state="state4"
                >
                    <b-form-input
                    type="number"
                    id="timeline-time"
                    v-model="objectif.dedicatedTime"
                    autofocus
                    min="1"
          :placeholder="$t('Choose_the_time_for_this_objective')"
                    :state="state4"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group         :label="$t('Add_Timeline')"
 label-for="add-timeline"
                invalid-feedback="Timeline is required"
                :state="state5">
                    <v-select
                        input-id="add-timeline"
                        v-model="objectif.timeline"
                                 :placeholder="$t('Select_Timeline')"

                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="timelines"
                        :state="state5"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group   :label="$t('When_time')" label-for="add-timeline"
                invalid-feedback="When (time) is required"
                :state="state6">
                    <v-select
                        input-id="add-timeline"
                       :placeholder="$t('Select_Time')"
                        v-model="objectif.time"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="whenptions"
                        :state="state6"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Tags" label-for="tag"
                invalid-feedback="Tags is required"
                :state="state7">
                    <v-select
                        v-model="objectif.tags"
                        placeholder="Select Tags"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="tagsOptions"
                        :reduce="(option) => option.value"
                        input-id="tags"
                        label="name"
                        multiple
                        :state="state7"
                    />
                    <template #option="{ label }">
                        <span class="ml-50 d-inline-block align-middle">
                            {{ label }}</span
                        >
                    </template>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormDatepicker
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from 'moment';
export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BButton,
        BFormDatepicker,
        flatPickr
    },
    props: {
        id: String,
        data: Object
    },
    data() {
        return {
            objectif : {},
            maxStartDate : null,
            minEndDate : null,
            maxStartTime : null,
            minEndTime : null,
            timelineOptions : ["Day","Week","Month"],
            timelines : [],
            whenptions : ["Morning","Afternoon","Evening","Night","Anytime"],
            tagsOptions:[],
            isTitleValid : true,
            isStartDateValid : true,
            isEndDateValid : true,
            isDeTime : true,
            isTimelineValid : true,
            isWhenValid : true,
            isTagsValid : true,
            isRight : true
        }
    },
    watch: {
        'objectif.startDate': function (newStartDate) {
            this.minEndDate = newStartDate
            this.minEndTime = moment(newStartDate, 'dddd, MMMM D, YYYY HH:mm').format('HH:mm');
            this.autoSelectTimeline()
        },
        'objectif.endDate': function (newEndDate) {
            this.maxStartDate = newEndDate
            this.maxStartTime = moment(newEndDate, 'dddd, MMMM D, YYYY HH:mm').format('HH:mm');
            this.autoSelectTimeline()
        },
    },
    mounted() {
        if (Object.keys(this.data).length !== 0) {
            this.objectif = JSON.parse(JSON.stringify(this.data));
        }
        else {
            axios.get("https://backendapinodejs.timecheckit.com/api/objectifs?id="+ this.id)
            .then((response) => {
                const num = parseInt(response.data.data.dedicatedTime)
                this.objectif = JSON.parse(JSON.stringify(response.data.data));
                this.objectif.objectifName = response.data.data.objectifName
                this.objectif.startDate = moment(response.data.data.startDate, 'YYYY-MM-DD HH:mm').format('dddd, MMMM D, YYYY HH:mm')
                this.objectif.endDate = moment(response.data.data.endDate, 'YYYY-MM-DD HH:mm').format('dddd, MMMM D, YYYY HH:mm')
                this.objectif.timeline = response.data.data.timeline
                this.objectif.time = response.data.data.time
                this.objectif.tags = response.data.data.tags.map((element) => ({
                    name : element.name,
                    value : element._id
                }))
            })
            .catch((error) => {
            console.error("There was an error!", error);
            });
        }
    },
    created() {
        this.timelines = this.timelineOptions
        this.getTags(); 
    },
    computed : {
        state1() {
            return this.isTitleValid ? null : false;
        },
        state2() {
            return this.isStartDateValid ? null : false;
        },
        state3() {
            return this.isEndDateValid ? null : false;
        },
        state4() {
            return this.isDeTime && this.isRight ? null : false;
        },
        state5() {
            return this.isTimelineValid ? null : false;
        },
        state6() {
            return this.isWhenValid ? null : false;
        },
        state7() {
            return this.isTagsValid ? null : false;
        },
        invalidTimeline() {
            if (!this.isDeTime) {
                return "Dedicated Time is required"
            }
            else {
                return `Please allocate a minimum of one hour for each of the ${this.calculTime()} tasks.`
            }
        }
    },
    methods: {
        getTags() {
            axios.get("https://backendapinodejs.timecheckit.com/api/tags?user=" + JSON.parse(localStorage.getItem("userData"))._id)
            .then((response) => {
                response.data.data.forEach(element => {
                this.tagsOptions.push({
                    name: element.name,
                    value: element._id
                }) 
                });
            })
            .catch((error) => {
                this.errorMessage = error.message;
            });
        },
        changeTimeline() {
            this.objectif.timeline = null
        },
        autoSelectTimeline() {
            const { startDate, endDate } = this.objectif;
            if (startDate && endDate) {
                const startDateTime = new Date(startDate);
                const endDateTime = new Date(endDate);
                const durationInDays = Math.floor(
                (endDateTime - startDateTime) / (24 * 60 * 60 * 1000)
                );
                if (durationInDays < 7) {
                // Less than 7 days
                this.timelines = this.timelineOptions.filter(option => option === "Day");
                } else if (durationInDays < 30) {
                // Less than 30 days
                this.timelines = this.timelineOptions.filter(option => option !== "Month");
                } else {
                this.timelines = this.timelineOptions
                // 30 days or more
                }
            }      
        },
        update(bvModalEvent) {
            this.isTitleValid = this.objectif.objectifName.trim() !== '';
            this.isStartDateValid = this.objectif.startDate.trim() !== '';
            this.isEndDateValid = this.objectif.endDate.trim() !== '';
            this.isDeTime = this.objectif.dedicatedTime.trim() !== '';
            this.isTimelineValid = this.objectif.timeline !== null;    
            this.isWhenValid = this.objectif.time !== null;    
            this.isTagsValid = this.objectif.tags !== null && this.objectif.tags.length !== 0 ;   
            if (this.isTitleValid &&
            this.isStartDateValid &&
            this.isEndDateValid &&
            this.isDeTime &&
            this.isTimelineValid &&
            this.isWhenValid &&
            this.isTagsValid) {
                const time = this.calculTime()
                this.isRight = this.objectif.dedicatedTime >= time
                if (this.isRight) {
                    this.$emit('objective', this.objectif);
                }
                else {
                    bvModalEvent.preventDefault()
                }            
            }
            else {
                bvModalEvent.preventDefault()
            }
        },
        calculTime() {
            var total = null
            const startDateObj = new Date(this.objectif.startDate);
            const endDateObj = new Date(this.objectif.endDate);
            const timeDiff = Math.abs(endDateObj.getTime() - startDateObj.getTime());
            const totalDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
            switch (this.objectif.timeline) {
                case 'Week':
                    total = Math.ceil(totalDays / 7);
                    break;
                case 'Month':
                    total = (endDateObj.getFullYear() - startDateObj.getFullYear()) * 12 + (endDateObj.getMonth() - startDateObj.getMonth()) + 1;
                    break;
                default:
                    total = totalDays
                    break;
            }
            return total
        }
        
    }
}
</script>